import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import CardAvatar from '../Avatar/CardAvatar';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const Title = styled.span`
  display: block;
  font-size: 0.875rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  margin: 0;
`;

const Subheader = styled(Title)`
  color: rgba(0, 0, 0, 0.54);
`;

const AvatarContainer = styled.div`
  margin-right: 16px;
`;

interface AuthorProps {
  image?: IGatsbyImageData;
  name?: string;
  title?: string;
  subheader?: string;
}

const Author = ({ image, title, subheader }: AuthorProps): JSX.Element => (
  <Box mt={2} mb={2}>
    <Container>
      <AvatarContainer>
        <CardAvatar width={60} height={60}>
          {image && <GatsbyImage image={image} alt={title || 'Profile'} />}
        </CardAvatar>
      </AvatarContainer>
      <Content>
        {title && <Title>{title}</Title>}
        {subheader && <Subheader>{subheader}</Subheader>}
      </Content>
    </Container>
  </Box>
);

export default Author;
