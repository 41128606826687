import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import SEO from '../components/seo';
import { Typography, Container, Box } from '@material-ui/core';
import { BlogPostBySlugQuery } from '../../types/graphql-types';
import RichContent from '../components/text/rich-content';
import Author from '../components/Blog/Author';

export interface BlogPost {
  data: BlogPostBySlugQuery;
}

const BlogPostPage = ({ data }: BlogPost): JSX.Element => {
  const { contentfulBlogPost } = data;

  return (
    <>
      <SEO title={contentfulBlogPost?.title || ''} />
      <Box mb={5} mt={5}>
        <Container>
          <Typography component="h1" variant="h3" align="left" color="textPrimary" gutterBottom>
            {contentfulBlogPost?.title}
          </Typography>
          <Author
            image={
              contentfulBlogPost?.author?.image?.gatsbyImageData as IGatsbyImageData | undefined
            }
            name={contentfulBlogPost?.author?.name || undefined}
            title={contentfulBlogPost?.author?.name || undefined}
            subheader={contentfulBlogPost?.author?.title || undefined}
          />
          <Typography variant="h6" align="left" color="textSecondary" component="p">
            {contentfulBlogPost?.publishDate}
          </Typography>
          {contentfulBlogPost?.heroImage?.gatsbyImageData && (
            <GatsbyImage
              image={contentfulBlogPost?.heroImage?.gatsbyImageData as IGatsbyImageData}
              alt={contentfulBlogPost?.title || 'Image'}
            />
          )}
          <RichContent
            dangerouslySetInnerHTML={{
              __html: contentfulBlogPost?.body?.childMarkdownRemark?.html || '',
            }}
          />
        </Container>
      </Box>
    </>
  );
};

export default BlogPostPage;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      publishDate(formatString: "MMMM DD, YYYY")
      heroImage {
        gatsbyImageData(width: 1232, quality: 90)
      }
      description {
        description
      }
      body {
        childMarkdownRemark {
          html
        }
      }
      author {
        title
        name
        image {
          gatsbyImageData(width: 60, height: 60, quality: 90)
        }
      }
    }
  }
`;
